<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0">
      <skeleton-list v-if="$store.state.accounts.LoaderMe || $store.state.purchases.retrieveLoader" />
      <template v-else>
        <PermissionDenied v-if="!$helpers.hasPermission(['view_purchase'])" />
        <template v-else>
          <v-empty-state :class="{'mt-n2' : routeList.includes($route.name)}" v-if="!purchasesList.length || (!routeList.includes($route.name) && !Object.keys($route?.query).length)" type="documents" id="documentos"
          :customClass="routeList.includes($route.name) ? 'mt-n6 pb-2' : 'mt-n8'" :isFree="routeList.includes($route.name)" title="No existen documentos para mostrar" :height="routeList.includes($route.name) ? 199 : 0" :hiddenDescription="hiddenBtnEmpty">
            <template v-slot:actions>
              <template v-if="!routeList.includes($route.name) && hiddenBtnEmpty">
                <v-btn @click="$emit('activeBtnFilter')" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <template v-else>
          <v-data-table
            :class="[{'table-not-rounded' : ['SupplierRetrieveUpdate'].includes($route.name)}, {'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="headerList"
            :items="purchasesList"
            :items-per-page="pageSize || pagination"
            hide-default-header
            disable-sort
            mobile-breakpoint
            hide-default-footer
            @click:row="(purchasesList) => {goTo(purchasesList.id)}"
            style="cursor: pointer;"
            v-model="selected"
            item-key="id"
            :show-select="!routeList.includes($route.name)"
            checkbox-color="primary"
            :fixed-header="!routeList.includes($route.name)"
            :height="heightTable"
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.supplier_name`]="{item}">
              <template v-if="item.supplier_name.length">
                <span :class="$route.name === 'SupplierRetrieveUpdate' ? 'body-2' : 'body-1 mb-n2'" class="d-inline-block text-truncate" style="max-width: 90%;">{{item.supplier_name}}</span>
                <span class="caption fontDraw--text d-block" v-if="item.tax_id">{{$t('generals.RUT')}} {{item.tax_id}}</span>
                <!-- <span class="body-2 text--disabled d-block font-italic" v-else>Sin especificar ---</span> -->
              </template>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.type`]="{item}">
              <span class="text-decoration-none fontBody--text">{{item.type | nameDocumentsType}}</span>
            </template>
            <template v-slot:[`item.number`]="{item}">
              <span class="body-1">{{item.number}}</span>
            </template>
            <template v-slot:[`item.date`]="{item}">
              <span class="body-1">{{item.date | date}}</span>
            </template>
            <template v-slot:[`item.rec_status`]="{item}">
              <chip-status style="white-space: nowrap !important" type="purchases" :status="item.rec_status ? item.rec_status : 'CRE'" :detail="item?.rec_status_info ?? null" :isTooltip="true" />
            </template>
            <template v-slot:[`item.status`]="{item}">
              <chip-status style="white-space: nowrap !important" type="documents" :status="item.status ? item.status : 'CRE'" :isTooltip="true" />
            </template>
            <template v-slot:[`item.due_date`]="{item}">
              <span v-if="item.due_date">{{item.due_date | date}}</span>
              <span v-else>---</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span>{{item.created | date}}</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
                <span class="body-1">{{item.created | dateTime}}</span>
              </template>
            <template v-slot:[`item.total`]="{item}">
              <div class="text-right">
                <span class="body-1">{{item.total | currency(item.type)}}</span>
                <span class="fontDraw--text caption ml-1">{{item.type | firstDocumentsTypeCurrency}}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="current = item, renderDocument2('purchases', 'rec-docs', 'pdf', item.id), resetPDF += 1" :ripple="false">
                    <v-list-item-title class="body-1 fontDraw--text d-flex align-center"><v-icon color="fontBody" small left>mdi-text-box</v-icon>Ver PDF</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-3" @click="downloadFile2('purchases', `${item.type}_N${item.number}`, 'rec-docs', 'xml', item.id)" :ripple="false">
                    <v-list-item-title class="body-1 fontDraw--text d-flex align-center"><v-icon color="fontBody" small left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <VMainPagination v-if="showPagination || showAll" :count="count" :pageSize="pageSize" redirect="purchasesList" />
          </template>
        </template>
      </template>
      <!-- dialog pdf -->
      <v-dialog v-model="modalRender" width="700" persistent scrollable no-click-animation overlay-color="overlay">
        <render-pdf :basicRender="true" :documentType="current.type | nameDocumentsType" :number="current.number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :showFormat="false" :key="resetPDF" />
      </v-dialog>
      <!-- end dialog pdf -->
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import RenderPdf from '@/components/files/RenderPdf'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ChipStatus from '@/components/commons/ChipStatus'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    ChipStatus,
    RenderPdf,
    VEmptyState,
    SkeletonList,
    VTableHeaders,
    VMainPagination,
    PermissionDenied
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin,
    RenderViewMixin
  ],
  props: {
    headerList: {
      type: Array,
      default: () => ([])
    },
    pageSize: {
      default: null
    }
  },
  data: () => ({
    resetPDF: 0,
    toggleDropdown: null,
    current: {},
    resetCheckbox: 0,
    isSelected: null,
    selected: [],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    routeList: ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'],
    headersDetail: [
      { text: 'Proveedor', value: 'supplier_name', sortable: true },
      { text: 'Tipo de documento', value: 'type', sortable: true },
      { text: 'Serie- Correlativo', value: 'number', align: 'end', sortable: true },
      { text: 'Fecha', value: 'date', align: 'end', sortable: true },
      { text: 'Estado comercial', value: 'rec_status', width: 130 },
      { text: 'Estado SUNAT', value: 'status', width: 130 },
      { text: 'Total', value: 'amount_total', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end', width: 16 }
    ]
  }),
  computed: {
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && (this.purchasesList.length < 17 || ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'].includes(this.$route.name))) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      count: state => state.purchases.recdocsCount,
      purchasesList: state => state.purchases.recdocsList
    }),
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (this.purchasesList.length) return false
      return false
    },
    currentHeader () {
      let list = [...this.headerList]
      return !this.$helpers.hasPermission(['view_swapresponse']) ? list.filter((h) => h.value !== 'status') : list
    }
  },
  watch: {
    selected (val) {
      if (val.length !== this.purchasesList.length) this.resetCheckbox += 1

      this.$emit('update:selectedDocuments', this.selected)
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.purchasesList
        this.isSelected = true
      } else if (val === '') this.selected = []
      this.$emit('update:toggleDropdown', val)
    }
  },
  methods: {
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.purchasesList
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'PurchaseRetrieve', params: { id: id, ...this.currentParams}})
    },
    latestRecords () {
     this.$router.replace({name: this.$route.name, params: this.currentParams, query: { ref: 'initial', approved: 'true'} }).catch(() => {})
    }
  }
}
</script>